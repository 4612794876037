import { Injectable } from "@angular/core";
import { Agent } from "./shared/models/agent";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AgentService } from "./services/agent.service";

@Injectable({ providedIn: 'root' })
export class AgentResolver implements Resolve<boolean> {
  constructor(private service: AgentService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.service.initAgent();
  }
}