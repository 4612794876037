<h1>Je souhaite</h1>

<span class="s100"><a  mat-raised-button (click)="setSell()" [ngStyle]="{ 'background-color': colorSell }"  style="margin-right: 17px;" class="s40 mat-mdc-raised-button-85"><span class="semot">🥂</span><br/>Vendre</a> <a  mat-raised-button (click)="setRent()"  [ngStyle]="{ 'background-color': colorRent }"  class="s40 mat-mdc-raised-button-85"><span class="semot">📝</span><br/>Louer</a></span>

<h1>Type de bien</h1>

<span class="s100"><a  mat-raised-button (click)="setMaison()"  [ngStyle]="{ 'background-color': colorHouse }"  style="margin-right: 17px;" class="s40 mat-mdc-raised-button-85"><span class="semot">🏠</span><br/>Maison</a> <a  mat-raised-button (click)="setAppartement()"  [ngStyle]="{ 'background-color': colorFlat }"  class="s40 mat-mdc-raised-button-85"><span class="semot">🏢</span><br/>Appartement</a></span>


<div class="txtCenter width100">
    <a  mat-raised-button routerLink="/adresse-bien" [ngStyle]="{ 'background-color': colorButton }" [disabled]="myError()" style="margin-top: 17px;">Suivant</a>
</div>