<h1>Estimation </h1>
<mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{ type }}"></mat-icon>
<p>Adresse : {{address}}  @ Lat={{latitude}} & Lon={{longitude}} </p>
<p>Bien  de {{ m2Habitable }}m² avec {{ pieces }} pièces sur {{ m2Terrain }}m² de terrain</p>
<p>{{ nom }} {{ prenom }} peut être contacté sur {{email}} ou {{telephone}}</p>

<mat-spinner *ngIf="!txtErrorMsg && !estimSaved"></mat-spinner>
<div *ngIf="estimSaved">
    D'ici quelques minutes vous recevrez votre estimation sur l'adresse email : {{ email }}
</div>
<div *ngIf="txtErrorMsg">{{txtErrorMsg}}</div>
