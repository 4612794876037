import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ParcelService } from './parcel.service';
import { Parcel } from '../shared/models/parcel';
import { SaveService } from './save.service';
import { Observable } from 'ol';
import { Agent } from '../shared/models/agent';
import { AgentService } from './agent.service';

@Injectable()
export class DataService {
    private type$ = new BehaviorSubject<string>("");
    storedType$ = this.type$.asObservable();
    private type: string=""

    private forSell$ = new BehaviorSubject<boolean>(false);
    private forSell: boolean;
    storedforSell$ = this.forSell$.asObservable();
    private forRent$ = new BehaviorSubject<boolean>(false);
    private forRent: boolean;
    storedforRent$ = this.forRent$.asObservable();

    private address$ = new BehaviorSubject<string>("");
    storedAddress$ = this.address$.asObservable();
    private address: string;
    private latitude$ = new BehaviorSubject<number>(999);
    private latitude: number=999;
    storedLatitude$ = this.latitude$.asObservable();
    private longitude$ = new BehaviorSubject<number>(999);
    private longitude: number=999;
    storedLongitude$ = this.longitude$.asObservable();
    
    private haveParcel$ = new BehaviorSubject<boolean>(false);
    storedhaveParcel$ = this.haveParcel$.asObservable();
    private idParcel$ = new BehaviorSubject<string>("");
    storedIdParcel$ = this.idParcel$.asObservable();
    private parcelGroundSQM$ = new BehaviorSubject<number>(0);
    storedParcelGroundSQM$ = this.parcelGroundSQM$.asObservable();
    private parcelBuildSQM$ = new BehaviorSubject<number>(-1);
    storedParcelBuildSQM$ = this.parcelBuildSQM$.asObservable();

    private m2Terrain$ = new BehaviorSubject<number>(-1);
    storedM2Terrain$ = this.m2Terrain$.asObservable();
    private m2Terrain: number;
    private m2Habitable$ = new BehaviorSubject<number>(-1);
    storedM2Habitable$ = this.m2Habitable$.asObservable();
    private m2Habitable:number;
    private pieces$ = new BehaviorSubject<number>(-1);
    storedPieces$ = this.pieces$.asObservable();
    private pieces:number;

    private nom$ = new BehaviorSubject<string>("");
    storedNom$ = this.nom$.asObservable();
    private nom:string;
    private prenom$ = new BehaviorSubject<string>("");
    storedPrenom$ = this.prenom$.asObservable();
    private prenom: string;
    private email$ = new BehaviorSubject<string>("");
    storedEmail$ = this.email$.asObservable();
    private email:string;
    private tel$ = new BehaviorSubject<string>("");
    storedTel$ = this.tel$.asObservable();
    private tel:string;

  
  constructor(private parcelService: ParcelService, private agentService: AgentService) {}

  setType(t: string) {
    this.type$.next(t);
    this.type=t;
  }

  setSell(t: boolean) {
    this.forSell$.next(t);
    this.forSell=t;
  }

  setRent(t: boolean) {
    this.forRent$.next(t);
    this.forRent=t;
  }

  setAddress(adr: string) {
    this.address$.next(adr);
    this.address=adr;
  }

  setHavePArcel(bool: boolean) {
    this.haveParcel$.next(bool);
  }

  setParcelBuildSQM(sqm: number) {
    this.parcelBuildSQM$.next(sqm);
  }

  setParcelGroundSQM(sqm: number) {
    this.parcelGroundSQM$.next(sqm);
  }

  setIdParcel(id: string) {
    this.idParcel$.next(id);
  }

  setLatitude(lat: number) {
    this.latitude$.next(lat);
    this.latitude=lat;
  }

  setLongitude(lon: number) {
      this.longitude$.next(lon);
      this.longitude=lon;
  }

  coordinatesValid() {
    return (this.longitude!=999 && this.latitude!=999);
  }

  setM2Terrain(m2: number) {
    this.m2Terrain$.next(m2);
    this.m2Terrain=m2;
  }

  setM2Habitable(m2: number) {
    this.m2Habitable$.next(m2);
    this.m2Habitable=m2;
  }

  setPieces(nb: number) {
    this.pieces$.next(nb);
    this.pieces=nb;
  }

  setNom(nom: string) {
    this.nom$.next(nom);
    this.nom=nom;
  }

  setPrenom(prenom: string) {
    this.prenom$.next(prenom);
    this.prenom=prenom;
  }

  setEmail(email: string) {
    this.email$.next(email)
    this.email=email;
  }

  setTelephone(tel: string) {
    this.tel$.next(tel)
    this.tel=tel;
  }

  updateParcel() {
    this.setHavePArcel(false);
    if(this.type=="m") { //only for house
      this.parcelService.getParcel(this.latitude,this.longitude).subscribe(
        (response: Parcel) => {                           //next() callback
          //console.log(response);
          if(response.id.length>=0) {
            this.setIdParcel(response.id);
            this.setParcelGroundSQM(response.groundSQM);
            this.setParcelBuildSQM(response.buildSQM);
            //console.log(response.id+" : "+response.buildSQM+" bati sur "+response.groundSQM)

          }
          else {
            //console.log("no parcel")
            this.setIdParcel("");
            this.setParcelGroundSQM(0);
            this.setParcelBuildSQM(-1);
          }
        },
        (error) => {                              //error() callback
          console.log(error);
        },
        () => {                                   //complete() callback

        })
      }

      
  }

  getAgent(): Agent {
    return this.agentService.agent;
  }

}