import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-informations-contact',
  templateUrl: './informations-contact.component.html',
  styleUrls: ['./informations-contact.component.scss']
})
export class InformationsContactComponent implements OnInit {

  constructor(private dataService: DataService) { }

  form: FormGroup;

  public myError = (controlName: string, errorName: string) =>{
    //console.log("checkError:"+controlName+"."+errorName+"="+(this.form.controls[controlName].hasError(errorName)))
    return this.form.controls[controlName].hasError(errorName);
 }

  ngOnInit(): void {
    this.form = new FormGroup({
      surname: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      telephone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)])
      });

      this.form.controls.surname.markAsTouched();
      this.form.controls.name.markAsTouched();
      this.form.controls.email.markAsTouched();
      this.form.controls.telephone.markAsTouched();
  }

  changeNom(event) {
    this.dataService.setNom(event.target.value);
  }

  changePrenom(event) {
    this.dataService.setPrenom(event.target.value);
  }

  changeEmail(event) {
    this.dataService.setEmail(event.target.value);
  }

  changeTelephone(event) {
    this.dataService.setTelephone(event.target.value);
  }

}
