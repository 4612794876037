import { Component,  OnInit } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import { OSM, Vector } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { DataService } from '../services/data.service';

//const markerIMG = require('../../assets/marker.png').default as string;

const markers: VectorLayer<Vector> = new VectorLayer({
  source: new Vector(),
  style: new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: '/assets/marker.png'
    })
  })
});

const  marker =  new Feature(new Point(fromLonLat([0,0])));;

const view = new View({
  center: [0, 0],
  zoom: 1,maxZoom: 20
});

const ZOOM_LEVEL=18;

var lon=999;
var lat=999;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {
  
  public map!: Map;
  latitude: number;
  longitude: number;
  markerDisplayed:boolean=false;

  

  constructor(private dataService: DataService) {

  }

  ngOnInit(): void {
    this.map= new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      target: 'map',
      view: view
    });

    this.map.addLayer(markers);

    this.dataService.storedLatitude$.subscribe((value) => {
      this.latitude = value;
      this.updateMarker();
    });
    this.dataService.storedLongitude$.subscribe((value) => {
      this.longitude = value;
      this.updateMarker();
    });

    this.map.on('singleclick', (event) => { this.onClick(event); this.dataService.setLongitude(lon);
      this.dataService.setLatitude(lat);  } ); 

  }

  updateMarker() {
    if(this.longitude!=999 && this.latitude!=999) {
      marker.setGeometry(new Point(fromLonLat([this.longitude,this.latitude])));
      let point=marker.getGeometry();
      //console.log("Geom Update : "+this.longitude+ " - " + this.latitude);
      if(!this.markerDisplayed) {
        this.markerDisplayed=true;
        markers.getSource().addFeature(marker);
        view.setCenter(point.getCoordinates());
        view.setZoom(ZOOM_LEVEL);   
      }
    }
  }
 
    onClick(args) {
     var lonLat = toLonLat(args.coordinate);
     lon=lonLat[0];
     lat=lonLat[1];
   }

 }

