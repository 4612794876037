import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from '../services/data.service';
import { SaveService } from '../services/save.service';


@Component({
  selector: 'app-estimation',
  templateUrl: './estimation.component.html',
  styleUrls: ['./estimation.component.scss']
})

export class EstimationComponent implements OnInit {
  DEBUG:boolean=true;

  type: string;
  subType: Subscription ;
  typeE: string;

  forSell: boolean;
  subForSell: Subscription ;
  forRent: boolean;
  subForRent: Subscription ;

  address: string;
  subAddress: Subscription ;
  latitude: number;
  subLatitude: Subscription ;
  longitude: number;
  subLongitude: Subscription ;

  m2Terrain: number;
  subM2Terrain: Subscription ;
  m2Habitable: number;
  subM2Habitable: Subscription ;
  pieces: number;
  subPieces: Subscription ;

  nom: string;
  subNom: Subscription ;
  prenom: string;
  subPrenom: Subscription ;
  email: string;
  subEmail: Subscription ;
  telephone: string;
  subTelephone: Subscription ;

  errorMsg$ = new BehaviorSubject<string>("");
  storedErrorMsg$ = this.errorMsg$.asObservable();
  subErrorMsg: Subscription;
  txtErrorMsg: string ="";
  

  estimSaved: boolean=false;


  constructor(private dataService: DataService, private saveService: SaveService) { }

  ngOnInit(): void {
    this.subType=this.dataService.storedType$.subscribe((value) => {
      this.type="-";
      if(value=="a") {
        this.type="Appartement";
        this.typeE="flat";
      }
      if(value=="m")  {
        this.type="Maison";
        this.typeE="house";
      }
      
      this.canEstimate();
    });

    this.subForSell=this.dataService.storedforSell$.subscribe((value: boolean) => {
      this.forSell = value;
      this.canEstimate();
    });

    this.subForRent=this.dataService.storedforRent$.subscribe((value: boolean) => {
      this.forRent = value;
      this.canEstimate();
    });

    this.subAddress= this.dataService.storedAddress$.subscribe((value: string) => {
      this.address = value;
      this.canEstimate();
    });
    this.subLatitude= this.dataService.storedLatitude$.subscribe((value: number) => {
      this.latitude = value;
      this.canEstimate();
    });
    this.subLongitude = this.dataService.storedLongitude$.subscribe((value: number) => {
      this.longitude = value;
      this.canEstimate();
    });


    this.subM2Terrain= this.dataService.storedM2Terrain$.subscribe((value: number) => {
      this.m2Terrain = value;
      this.canEstimate();
    });
    this.subM2Habitable= this.dataService.storedM2Habitable$.subscribe((value: number) => {
      this.m2Habitable = value;
      this.canEstimate();
    });
    this.subPieces = this.dataService.storedPieces$.subscribe((value: number) => {
      this.pieces = value;
      this.canEstimate();
    });
    

    this.subNom= this.dataService.storedNom$.subscribe((value: string) => {
      this.nom = value;
      this.canEstimate();
    });
    this.subPrenom = this.dataService.storedPrenom$.subscribe((value: string) => {
      this.prenom = value;
      this.canEstimate();
    });
    this.subEmail = this.dataService.storedEmail$.subscribe((value: string) => {
      this.email = value;
      this.canEstimate();
    });
    this.subTelephone= this.dataService.storedTel$.subscribe((value: string) => {
      this.telephone = value;
      this.canEstimate();
    });

    this.subErrorMsg = this.storedErrorMsg$.subscribe((value: string) => {
      this.txtErrorMsg = value;
    });
    let launchEstim=false;
    while(launchEstim==false && this.txtErrorMsg=="" && this.canEstimate()) {
      launchEstim=true;
      this.subErrorMsg.unsubscribe();
         this.saveService.getEstimation(this.nom,this.prenom,this.address,this.email,this.telephone,this.forRent,this.forSell,this.type,this.m2Habitable,this.m2Terrain,this.pieces,this.longitude,this.latitude).subscribe(
          (response) => {                           //next() callback
            //console.log(response);
            if(response.result===true) {
              this.estimSaved=true;
            }
            else {
              this.txtErrorMsg=response.erreurs;
            }

          },
          (error) => {                              //error() callback
            this.txtErrorMsg=error.message;
            console.log(error);
          },
          () => {                                   //complete() callback
  
          });

   }
  }

  setErrorMsg(msg) {
    this.errorMsg$.next(msg);
  }

  canEstimate() {
    let ok=true;
    let errorMsg="";
    if(this.typeE!='house' && this.typeE!='flat') {
      ok=false;
      errorMsg+="Mauvais type de bien \n";
    }
    if(this.latitude==999) {
      ok=false;
      errorMsg+="Latitude incorrecte \n";
    }
    if(this.longitude==999) {
      ok=false;
      errorMsg+="Longitude incorrecte \n";
    }
    if(this.m2Habitable==-1) {
      ok=false;
      errorMsg+="m² habitable incorrect \n";
    }
    if(this.m2Terrain==-1) {
      ok=false;
      errorMsg+="m² terrain incorrect \n";
    }
    if(this.pieces==-1) {
      ok=false;
      errorMsg+="Nombre de pieces incorrect \n";
    }
    if(!(this.forRent || this.forSell)) {
      ok=false;
      errorMsg+="Pas de type d'estimation \n";
    }
    this.setErrorMsg(errorMsg);
    return ok;

  }

}
