<h1>Details</h1>
<mat-card *ngIf="parcelID" class="width100">
    <mat-card-content>Pour ce bien, des données ont été pré-renseignées.<br/> Ajustez le terrain et la supérficie habitable si besoin.</mat-card-content>
</mat-card>
<form [formGroup]="form" validate >
    
    <mat-form-field  class="width100" >
        <mat-label>
            Superficie terrain (m²) 
        </mat-label>
        <input matInput id="ground" formControlName="ground" class="width100"  (change)="changeTerrain($event)"  type="number"/>
        <mat-error *ngIf="myError('ground', 'required')">Superficie terrain obligatoire</mat-error>
        <mat-error *ngIf="myError('ground', 'min')">La superficie terrain doit être supérieure ou égale à 0</mat-error>
    </mat-form-field>

    <mat-form-field  class="width100" >
        <mat-label>
            Superficie Habitable (m²) 
        </mat-label>
        <input matInput id="building" formControlName="building" class="width100"  (change)="changeHabitable($event)" type="number"/>
        <mat-error *ngIf="myError('building', 'required')">Superficie habitable obligatoire</mat-error>
        <mat-error *ngIf="myError('building', 'min')">La superficie habitable doit être supérieure à 0</mat-error>
    </mat-form-field>

    <mat-form-field class="width100" >
        <mat-label>
            Nombre de pieces
        </mat-label>
        <input matInput id="rooms"  formControlName="rooms" class="width100" (change)="changePieces($event)"  type="number"/>
        <mat-error *ngIf="myError('rooms', 'required')">Nombre de pièce(s) obligatoire</mat-error>
        <mat-error *ngIf="myError('rooms', 'min')">Le nombre de pièce(s) doit être supérieur à 0</mat-error>
    </mat-form-field>
    <div class="width100 txtCenter">
        <a  mat-raised-button routerLink="/informations-contact"  color="accent" [disabled]="!form.valid">Suivant</a>
    </div>
</form>
<!-- <button  mat-raised-button routerLink="/estimation"  color="accent">Estimer</button> -->