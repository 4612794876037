import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-detail-bien',
  templateUrl: './detail-bien.component.html',
  styleUrls: ['./detail-bien.component.scss']
})
export class DetailBienComponent implements OnInit {

  constructor(private dataService: DataService) { }

  type: string = "";
  typeE: string= "";

  parcelID:string= "";

  subIdParcel: Subscription;
  subpParcelGound: Subscription;
  subpParceBuild: Subscription;

   form: FormGroup;

   public myError = (controlName: string, errorName: string) =>{
      //console.log("checkError:"+controlName+"."+errorName)
      return this.form.controls[controlName].hasError(errorName);
   }
  ngOnInit(): void {

    this.form = new FormGroup({
      ground: new FormControl('', [Validators.required, Validators.min(0)]),
      building: new FormControl('', [Validators.required, Validators.min(1)]),
      rooms: new FormControl('', [Validators.required, Validators.min(1)])
      });

      
    this.dataService.storedType$.subscribe((value) => {
      this.type="-";
      if(value=="a") {
        this.type="Appartement";
        this.typeE="flat";
      }
      if(value=="m")  {
        this.type="Maison";
        this.typeE="house";
      }
    });

    this.form.controls.ground.markAsTouched();
    this.form.controls.building.markAsTouched();
    this.form.controls.rooms.markAsTouched();

    this.subIdParcel=this.dataService.storedIdParcel$.subscribe((value: string) => {
      if(value.length>=0) {
         this.parcelID=value;
      }
    });

    this.subpParcelGound = this.dataService.storedParcelGroundSQM$.subscribe((value: number) => {
      if(value>=0) {
         this.form.controls.ground.setValue(value);
        this.dataService.setM2Terrain(value);
      }
    });
    this.subpParceBuild = this.dataService.storedParcelBuildSQM$.subscribe((value: number) => {
      if(value>=0) {
        this.form.controls.building.setValue(value);
        this.dataService.setM2Habitable(value);
      }
    });
  }

  changeTerrain(event) {
    this.dataService.setM2Terrain(event.target.value);
  }

  changeHabitable(event) {
    this.dataService.setM2Habitable(event.target.value);
  }

  changePieces(event) {
    this.dataService.setPieces(event.target.value);
  }
}
