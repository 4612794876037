export class Agent {
    constructor (
    public id: string,
    public label: string,
    public bg_color: string,
    public button_bg_color: string,
    public button_bg_color_off: string,
    public form_bg_color: string,
    public form_bg_color_off: string,
    public error_color: string
    ) {}

    isValid() {
        if(this.id.length>0) {
            return true;
        }
        return false;
    }


}