import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { BASE_PELIAS_URL } from '../app.constants';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../services/data.service';

/*
export interface User {
  name: string;
}
*/
@Component({
  selector: 'app-adresse-bien',
  templateUrl: './adresse-bien.component.html',
  styleUrls: ['./adresse-bien.component.scss']
})



export class AdresseBienComponent implements OnInit {

  myControl = new FormControl();
  filteredOptions: any;
  minLengthTerm = 3;
  isLoading = false;
  errorMsg!: string;
  selectedAddress: any;

  form: FormGroup;

  constructor(private dataService: DataService, private http: HttpClient) {

  }

  onSelected() {
    let txt= this.myControl.value;
    for (let adr of this.filteredOptions) {
      if(txt==adr["properties"]["label"]) {
        this.selectedAddress=adr;
        this.form.controls.address.setValue(this.selectedAddress["properties"]["label"]);
        //this.form.controls.address.markAsPristine();
        this.dataService.setAddress(this.selectedAddress["properties"]["label"]);
        this.dataService.setLatitude(this.selectedAddress["geometry"]["coordinates"][1]);
        this.dataService.setLongitude(this.selectedAddress["geometry"]["coordinates"][0]);
        this.dataService.updateParcel();
      }
    }
    //console.log(this.selectedAddress);

  }

  myError() {
    return (!this.dataService.coordinatesValid());
  }

  ngOnInit() {
    this.form = new FormGroup({
      address: new FormControl('', [Validators.required])
    });

    this.form.controls.address.markAsTouched();

    this.myControl.valueChanges
    .pipe(
      filter(res => {
        return res !== null && res.length >= this.minLengthTerm
      }),
      distinctUntilChanged(),
      debounceTime(375),
      tap(() => {
        this.errorMsg = "";
        this.filteredOptions = [];
        this.isLoading = true;
      }),
      switchMap(value => this.http.get(BASE_PELIAS_URL+"?text="+value)
        .pipe(
          finalize(() => {
            this.isLoading = false
          }),
        )
      )
    )
    .subscribe((data: any) => {
      if (data['features'] == undefined) {
        this.errorMsg = 'No Result';
        this.filteredOptions = [];
      } else {
        this.errorMsg = "";
        this.filteredOptions = data['features'];
      }
      /* console.log(this.filteredOptions); */
    });
  }
}




