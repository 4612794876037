// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

export const DEFAULT_LATITUDE = 46.227638;
export const DEFAULT_LONGITUDE = 2.213749;


export const BASE_PELIAS_URL: string = '/api/geo';
export const BASE_MODEL_URL: string = '/api/estimation';
export const BASE_SAVE_URL: string = '/api/estimation/save';
export const BASE_PARCEL_URL: string = '/api/parcelles';
export const BASE_AGENT_URL: string = '/api/agent'
export const BASE_DOMAIN: string = "immo-est.binard.biz";
//export const BASE_PELIAS_URL: string = 'immo-est.binard.biz/api/geo';
export const DEFAULT_VIEW_BOX: string = 'viewbox=-25.0000%2C70.0000%2C50.0000%2C40.0000';
