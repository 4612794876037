

import { Injectable } from '@angular/core';
 
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BASE_SAVE_URL } from '../app.constants';
 
@Injectable()
export class SaveService {
 
   
  constructor(private http: HttpClient) {
  }
 
  getEstimation(nom: string, prenom: string, adresse: string, email: string, telephone: string, demande_a: boolean, demande_l: boolean,
                 type: string, bati: number, terrain: number, pieces: number, lon: number,lat: number): Observable<any> {
    const url: string = BASE_SAVE_URL+"?telephone="+telephone+"&email="+email+"&nom="+nom+"&prenom="+prenom+"&adresse="+adresse+"&type="+type+"&bati="+bati+"&terrain="+terrain+"&pieces="+pieces+"&longitude="+lon+"&latitude="+lat+"&demande_a="+demande_a+"&demande_l="+demande_l;
    //console.log(url);
    return this.http.get(url);
  }
  
}
