import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AdresseBienComponent } from './adresse-bien/adresse-bien.component';
import { ChoixBienComponent } from './choix-bien/choix-bien.component';
import { DetailBienComponent } from './detail-bien/detail-bien.component';
import { EstimationComponent } from './estimation/estimation.component';
import { InformationsContactComponent } from './informations-contact/informations-contact.component';
import { AgentResolver } from './agentResolver';


const routes: Routes = [
  { path: 'type-bien', component: ChoixBienComponent },
  { path: 'adresse-bien', component: AdresseBienComponent },
  { path: 'detail-bien', component: DetailBienComponent },
  { path: 'informations-contact', component: InformationsContactComponent },
  { path: 'estimation', component: EstimationComponent },
  { path: '**', component: ChoixBienComponent, resolve: {    agent: AgentResolver   } }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
