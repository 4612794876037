<h1>Moyens de contact</h1>
<form [formGroup]="form" validate >
    <mat-form-field class="width100" >
        <mat-label>
            Nom
        </mat-label>
        <input matInput id="surname" formControlName="surname" class="width100"  (change)="changeNom($event)" type="text"/>
        <mat-error *ngIf="myError('surname', 'required')">Merci de saisir un Nom</mat-error>
    </mat-form-field>

    <mat-form-field class="width100" >
        <mat-label>
            Prenom
        </mat-label>
        <input matInput id="name" formControlName="name" class="width100"  (change)="changePrenom($event)" type="text"/>
        <mat-error *ngIf="myError('name', 'required')">Merci de saisir un Prénom</mat-error>
    </mat-form-field>

    <mat-form-field class="width100" >
        <mat-label>
            Email
        </mat-label>
        <input matInput id="email" formControlName="email" class="width100"  (change)="changeEmail($event)" type="email"/>
        <mat-error *ngIf="myError('email', 'required')">L'email est obligatoire</mat-error>
        <mat-error *ngIf="myError('email', 'email')">Adresse email invalide</mat-error>
    </mat-form-field>

    <mat-form-field class="width100" >
        <mat-label>
            Téléphone
        </mat-label>
        <input matInput id="telephone" minlength="10" maxlength="10" formControlName="telephone" class="width100" (change)="changeTelephone($event)" type="tel"/>
        <mat-error *ngIf="myError('telephone', 'minlength') || myError('telephone', 'maxlength')">Numéro téléphone invalide</mat-error>
        <mat-error *ngIf="myError('telephone', 'required')">Le télépgone est obligatoire</mat-error>
    </mat-form-field>
    <div class="txtCenter width100">
        <button  mat-raised-button routerLink="/estimation"  color="primary"  [disabled]="!form.valid">Estimer</button>
    </div>
</form>