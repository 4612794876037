import { Component, HostBinding, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Agent } from '../shared/models/agent';



@Component({
  selector: 'app-choix-bien',
  templateUrl: './choix-bien.component.html',
  styleUrls: ['./choix-bien.component.scss']
})
export class ChoixBienComponent implements OnInit {
  
  colorHouse: string ;
  colorFlat: string ;

  colorSell: string ;
  colorRent: string ;

  colorButton: string ;

  agent: Agent;

  typeSelected : boolean = false;

  forSell: boolean = false;
  forRent: boolean = false;

  constructor(private dataService: DataService) {
    this.agent=this.dataService.getAgent();
    this.colorHouse=this.agent.button_bg_color_off;
    this.colorFlat=this.agent.button_bg_color_off;
    this.colorSell=this.agent.button_bg_color_off;
    this.colorRent=this.agent.button_bg_color_off;
    this.colorButton=this.agent.form_bg_color_off
   }

  myError() {
    let error = !this.typeSelected || !(this.forSell || this.forRent);;
    if(error) {
      this.colorButton=this.agent.form_bg_color_off;
      return true;
    }
    else {
      this.colorButton=this.agent.form_bg_color;
      return false;
    }
  }
    

  ngOnInit(): void {
  }

  setMaison() {
    this.dataService.setType("m");
    this.typeSelected=true;
    this.colorHouse=this.agent.button_bg_color;
    this.colorFlat = this.agent.button_bg_color_off;
    this.myError();
  }   

  setAppartement() {
    this.dataService.setType("a");
    this.typeSelected=true;
    this.colorFlat=this.agent.button_bg_color;
    this.colorHouse=this.agent.button_bg_color_off;
    this.myError();
  }

  setTerrain() {
    this.dataService.setType("t"); 
  }


  setSell() {
    this.forSell=!this.forSell;
    if(this.forSell) { this.colorSell=this.agent.button_bg_color; }  else {  this.colorSell=this.agent.button_bg_color_off;  }
    this.dataService.setSell(this.forSell);
    this.myError();
  }

  setRent() {
    this.forRent=!this.forRent;
    if(this.forRent) { this.colorRent=this.agent.button_bg_color; }  else {  this.colorRent=this.agent.button_bg_color_off; ;  }
    this.dataService.setRent(this.forRent);
    this.myError();
  }

}
