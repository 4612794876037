<h1>Localisation</h1>
<form [formGroup]="form" validate >
  <mat-form-field appearance="fill" class="width100">
      <mat-label>Adresse</mat-label>
      <input type="text" id="address" formControlName="address" matInput [formControl]="myControl" class="width100" [matAutocomplete]="auto" >
      <mat-error *ngIf="myError()">Merci de saisir une adresse ou choisir un lieu sur la carte</mat-error>
      <mat-autocomplete  [panelWidth]="auto"  #auto="matAutocomplete" (optionSelected)="onSelected()">
        <mat-option *ngIf="isLoading" class="is-loading">Chargement...</mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let option of filteredOptions" [value]="option.properties.label">
            {{option.properties.label}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
    
  <app-map></app-map>

    <ng-container *ngIf="errorMsg">
      {{errorMsg}}
    </ng-container>
  <div class="txtCenter width100">
      <a  mat-raised-button routerLink="/detail-bien"  color="accent" [disabled]="myError()" style="margin-top: 17px;">Suivant</a>
  </div>
</form>