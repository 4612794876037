
import { Injectable } from '@angular/core';
 
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BASE_PARCEL_URL } from '../app.constants';
import { Parcel } from '../shared/models/parcel';
import { map } from 'rxjs/operators';
 
@Injectable()
export class ParcelService {
 
   
  constructor(private http: HttpClient) {
  }
 
  getParcel(lat: number, lon: number): Observable<Parcel> {
    const url: string = BASE_PARCEL_URL+"?longitude="+lon+"&latitude="+lat;
    return this.http.get(url).pipe(
      map((item) => { 
          return new Parcel( 
            item['id'],item['terrain'],item['bati']
          );
        })
    );
  }
 
}