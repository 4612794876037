
import { Injectable } from '@angular/core';
 
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';


import { BASE_PELIAS_URL } from '../app.constants';
import { Address } from '../shared/models/address.model';
 
@Injectable()
export class PeliasService {
 
   
  constructor(private http: HttpClient) {
  }
 
  autocomplete(text: string | Address): Observable<Address> {
    const url: string = BASE_PELIAS_URL+"?text="+text;
    //console.log(url);
    return this.http.get(url).pipe(
      map((item) => { 
          return new Address( 
              'id',
              item['properties']['label'],
              0,
              0
          );
        })
    );
  }
 
}