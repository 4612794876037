export class Parcel {
  id: string;
  groundSQM: number;
  buildSQM: number;
  constructor (  id: string,  groundSQM: number,  buildSQM: number  ) {
    this.id=id;
    this.buildSQM=buildSQM;
    this.groundSQM=groundSQM;
  }
}
