import {Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Est-Immo';
  
 constructor(private activatedRoute: ActivatedRoute) {}

 ngOnInit() {
   this.activatedRoute.data.subscribe(({ hero }) => {
     // do something with your resolved data ...
   })
 }
}


