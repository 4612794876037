

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
 
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom, isObservable, lastValueFrom  } from 'rxjs';

import { BASE_AGENT_URL, BASE_DOMAIN } from '../app.constants';
import { Agent } from '../shared/models/agent';
import { map } from 'rxjs/operators';
import { waitForAsync } from '@angular/core/testing';
 
@Injectable()
export class AgentService {
 
  private id: string;
  private isPromisePending:boolean;
  public agent: Agent=null;
   
  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any) {
    let domain = this.document.location.hostname;
    domain=domain.replace(".localhost","");
    domain=domain.replace("."+BASE_DOMAIN,"");
    console.log(domain);
    if(domain==BASE_DOMAIN || domain=="www" || domain=="localhost") {
      let url= this.document.location.hostname;
      console.log(url);      
      url=url.replace("www","demo");
      console.log(url);
      if(!url.includes("demo.")) {
        url="demo."+url;
      }
      if(url.includes("localhost")) {
        window.location.href = "http://"+url+":4200";
      }
      else {
        window.location.href = "https://"+url;
      }
      
    }
    else {
      console.log("load="+domain);
      this.id=domain;
    }
    

  }
 
  initAgent(): Observable<boolean> {
    const url: string = BASE_AGENT_URL+"/"+this.id;

    return this.http.get(url).pipe(
      map((item) => { 
        console.log(item);
        if(item["erreurs"]=="") {
          this.agent= new Agent( 
            item['id'],
            item['label'],
            item['bg_color'],
            item['button_bg_color'],
            item['button_bg_color_off'],
            item['form_bg_color'],
            item['form_bg_color_off'],
            item['error_color']
          );
          console.log(this.agent);
          return true;
        }
        else {
          this.agent=new Agent("DEMO","DEMO","#ffffff","rgb(63, 81, 181)","rgba(255, 255, 255, 0);","rgb(233, 30, 99)","rgba(255, 255, 255, 0);","")
          console.error(this.agent);
        }
        return false;
        })
        
    );
      
    
  }
  
 
}
