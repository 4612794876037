import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ChoixBienComponent } from './choix-bien/choix-bien.component';
import { DetailBienComponent } from './detail-bien/detail-bien.component';
import { InformationsContactComponent } from './informations-contact/informations-contact.component';
import { EstimationComponent } from './estimation/estimation.component';

import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';

import { ModelService } from './services/model.service';
import { DataService } from './services/data.service';
import { AdresseBienComponent } from './adresse-bien/adresse-bien.component';
import { PeliasService } from './services/pelias.service';
import { MapComponent } from './map/map.component';
import { ParcelService } from './services/parcel.service';
import { SaveService } from './services/save.service';
import { AgentService } from './services/agent.service';


@NgModule({
  declarations: [
    AppComponent,
    ChoixBienComponent,
    DetailBienComponent,
    InformationsContactComponent,
    EstimationComponent,
    AdresseBienComponent,
    MapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatCardModule
  ],
  providers: [
    SaveService,
    DataService,
    ParcelService,
    PeliasService,
    AgentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
